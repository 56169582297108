import React from "react";
import Content, {HTMLContent} from "../components/Content";
import { Helmet } from "react-helmet";
import UpNext from "../components/UpNext";
import SEO from "../components/SEO";
import { graphql } from "gatsby";

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';



// eslint-disable-next-line
const TermsPage = (props) => {

    const title = props.data.markdownRemark.frontmatter.title;
    const tou = props.data.markdownRemark.frontmatter.tou;
    const privacy = props.data.markdownRemark.frontmatter.privacy;
    const podcast = props.data.markdownRemark.frontmatter.podcast;
    const PageContent = HTMLContent || Content;
    console.log(props.data.markdownRemark.frontmatter.tou);
  return (
    <React.Fragment>
      <SEO {...props.data.seo} />
      <Helmet bodyAttributes={{ class: "page-terms" }} />
      <section className="section terms">
        <div className="container">
          <div className="columns is-multiline">
            <div className="page-title column is-full">
              <h1 className="title has-text-centered">{title}</h1>
            </div>
            <div className="column page-content">
                <Tabs
                  defaultActiveKey="tou"
                  id="uncontrolled-tab-example"
                  className="  list-unstyled"
                  style={{ listStyleType: "none", listStyle: "none" }}
                >
                  <Tab eventKey="tou" title="Terms of Use" style={{ listStyleType: "none", listStyle: "none"}}>
                    <PageContent content={tou}></PageContent>
                  </Tab>
                  <Tab eventKey="privacy" title="Privacy Policy">
                    <PageContent content={privacy}></PageContent>
                  </Tab>
                  <Tab eventKey="podcast" title="Podcast Disclaimer">
                    <PageContent content={podcast}></PageContent>
                  </Tab>
                </Tabs>
                <UpNext url="/" text="Home"/>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

TermsPage.propTypes = {
};

export default TermsPage;

export const termsQuery = graphql`
query QueryTerms($id: String!) {
  markdownRemark(id: {eq: $id}) {
    frontmatter {
      title
      tou
      privacy
      podcast
    }
  }
  seo: markdownRemark(id: {eq: $id}) {
    ...SEOFragment
  }
}
`;
